<template>
  <div class="login">
    <b-modal v-model="isModalEncuestaActive" has-modal-card trap-focus :destroy-on-hide="false" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span><font-awesome-icon class="icono-modal white" :icon="['fal', 'poll']" />
                <span class="title-m bold white">{{
                  $t("SATISFACTION_SURVEY.TITLE")
                }}</span></span>
            </div>
          </div>
          <div class="stimulus text-l gray" v-if="questions.length > 0 && responses.respuestas.length > 0">
            <div class="py-1 has-text-left">
              {{ $t("SATISFACTION_SURVEY.DESCRIPTION") }}
            </div>
            <div class="pt-1 pb-3 has-text-left" v-html="$t('SATISFACTION_SURVEY.MESSAGE')">
            </div>
            <div class="question py-3" v-for="(question, index) in questions" :key="index">
              <p class="has-text-weight-semibold"><span>{{ question.orden }}. </span>{{ question.pregunta }}</p>
              <div class="block pt-3">
                <b-radio v-model="responses.respuestas[index].respuesta" :name="question.id.toString()" native-value="S">
                  {{ $t("SATISFACTION_SURVEY.RESPONSE_YES") }}
                </b-radio>
                <b-radio v-model="responses.respuestas[index].respuesta" :name="question.id.toString()" native-value="N">
                  {{ $t("SATISFACTION_SURVEY.RESPONSE_NO") }}
                </b-radio>
              </div>
            </div>
          </div>
          <div class="parte-inferior">
            <div>
              <button class="btn-modal btn button" @click="sendSurvey()" :disabled="checkIfSurveyIsAnswered">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'paper-plane']" />
                  <span class="text-l white">{{
                    $t("STIMULUS.send")
                  }}</span></span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <div class="background">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box">
              <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <img :src="mySVG" />
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-10 is-centered is-flex is-justify-content-center">
                  <span class="message-thanks title-l bold">{{
                    $t("COMPETITION.TITLE_THANKS")
                  }}</span>
                </div>
              </div>
<!--               <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <p class="text-l gray" v-html="$t('COMPETITION.TEXT_PRIX')"></p>
                </div>
              </div> -->
              <div class="columns is-centered">
                <div class="column is-8 is-flex is-justify-content-center">
                  <p class="text-l gray">
                    {{ $t("COMPETITION.TEXT_CERT") }}
                  </p>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-4">
                  <button class="btn button" @click="downloadDiploma()">
                    <span class="content-button">
                      <font-awesome-icon class="icono" :icon="['fal', 'award']" />
                      <span class="text-m black save-text">{{
                        $t("COMPETITION.DOWNLOAD_CERT")
                      }}</span>
                    </span>
                  </button>
                </div>
                <div class="column is-4 is-flex is-justify-content-center">
                  <button class="button btn-go-back" @click="volver()">
                    <span><b>{{ $t("THANKS_YOU.CLOSE") }}</b></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alumnoCertificado",
  components: {},
  data() {
    return {
      isModalEncuestaActive: false,
      mySVG: require("../assets/icons/thankyou.svg"),
      questions: [],
      responses: {
        respuestas: []
      }
    };
  },
  async created() {
    const isAnswered = localStorage.getItem('satisfactionSurveyAnswered');
    if (!isAnswered) {
      const response = await this.$store.dispatch("getPreguntas", { codigoEncuesta: 'SAT', idioma: this.$i18n.locale });
      for (let question of response) {
        this.responses.respuestas.push({ pregunta_id: question.id, respuesta: "" });
      }
      this.isModalEncuestaActive = true;
      this.questions = response;
    }
  },
  destroyed() {
    this.$store.commit("setCurrentUser", null);
    localStorage.removeItem("currentUser");
  },
  methods: {
    downloadDiploma() {
      this.$store.commit("setShowSpinner", true);
      this.$store
        .dispatch("downloadDiploma", {
          idioma: this.$i18n.locale,
          usuario_id: this.$store.state.users.currentUser.id,
        })
        .then((response) => {
          const file = new Blob([response], {
            type: "application/pdf",
          });
          this.$store.commit("setShowSpinner", false);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = `diploma_${this.$store.state.users.currentUser.id}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$store.commit("setShowSpinner", false);
        });
    },

    volver() {
      this.$store.commit("setCurrentUser", null);
      localStorage.removeItem("currentUser");
      this.$router.push("/login");
    },
    sendSurvey() {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("responderEncuesta", { encuestaSatisfaccion: this.responses }).then(response => {
        localStorage.setItem('satisfactionSurveyAnswered', 'S');
        this.isModalEncuestaActive = false;
        this.$store.commit("setShowSpinner", false);
      }).catch(error => {
        this.$store.commit("setShowSpinner", false);
      });
    }
  },
  computed: {
    checkIfSurveyIsAnswered() {
      return this.responses.respuestas.some(obj => obj.respuesta === "");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

a {
  color: $primary;
}

.background {
  position: absolute;
  background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /* background-attachment: fixed; */
  width: 100%;
  height: 100%;
}

.background:before {
  content: " ";
  position: absolute;
  top: 0;
  z-index: 0;

  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.box {
  background-color: white;
  border-radius: 0.1rem;
  padding: 3.5rem 0;
  margin-top: 30%;
  margin-bottom: 2rem;
}

.message-thanks {
  color: black;
  line-height: 40px;
  text-align: center;
}

.btn-go-back {
  background-color: $primary;
  color: white;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.btn-go-back:hover {
  color: white;
}

.text-l {
  text-align: center;
}

.btn {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parte-inferior {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.parte-superior.welcome {
  justify-content: flex-end;
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.b-radio.radio input[type=radio]+.check:before {
  background-color: $primary !important;
}
</style>